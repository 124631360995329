var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("PageCard", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("TaskManagerHeader", {
              attrs: {
                "show-table": _vm.showTable,
                "show-calendar": _vm.showCalendar,
                "tasks-scheduler": _vm.tasksScheduler,
              },
              on: {
                "update:showTable": function ($event) {
                  _vm.showTable = $event
                },
                "update:show-table": function ($event) {
                  _vm.showTable = $event
                },
                "update:showCalendar": function ($event) {
                  _vm.showCalendar = $event
                },
                "update:show-calendar": function ($event) {
                  _vm.showCalendar = $event
                },
                "update:tasksScheduler": function ($event) {
                  _vm.tasksScheduler = $event
                },
                "update:tasks-scheduler": function ($event) {
                  _vm.tasksScheduler = $event
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _vm.showTable
              ? _c("TaskManagerTable", {
                  attrs: {
                    "tasks-scheduler": _vm.tasksScheduler,
                    "schedules-status-logs": _vm.schedulesStatusLogs,
                    loading: _vm.loading,
                  },
                })
              : _vm._e(),
            _vm.showCalendar
              ? _c("TaskManagerCalendar", {
                  attrs: {
                    "tasks-scheduler": _vm.tasksScheduler,
                    "schedules-status-logs": _vm.schedulesStatusLogs,
                  },
                  on: {
                    "update:tasksScheduler": function ($event) {
                      _vm.tasksScheduler = $event
                    },
                    "update:tasks-scheduler": function ($event) {
                      _vm.tasksScheduler = $event
                    },
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }