var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.status !== undefined
    ? _c("span", { class: `status ${_vm.getStatus}` }, [
        _vm._v(" " + _vm._s(_vm.$t(_vm.getStatus)) + " "),
      ])
    : _c("span", [
        _vm._v(" " + _vm._s(_vm.$t("taskManager.table.status.notRun")) + " "),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }