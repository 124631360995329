
import mixins from 'vue-typed-mixins';
import PageCard from '@/common/template/PageCard.vue';
import TaskManagerHeader from '@/resources/task-manager/components/TaskManagerHeader.vue';
import TaskManagerTable from '@/resources/task-manager/components/TaskManagerTable.vue';
import TaskSchedulerService from '@/common/services/TaskSchedulerService';
import { SchedulesStatusLogs, TaskScheduler } from '@/types';
import { mapMutations } from 'vuex';
import NotificationMixin from '@/mixins/NotificationMixin';

export default mixins(NotificationMixin).extend({
  name: 'TaskManager',
  components: {
    PageCard,
    TaskManagerHeader,
    TaskManagerTable,
    TaskManagerCalendar: () => import('@/resources/task-manager/components/TaskManagerCalendar.vue'),
  },
  data() {
    return {
      showTable: true,
      showCalendar: false,
      tasksScheduler: [] as TaskScheduler[],
      loading: true,
      schedulesStatusLogs: [] as SchedulesStatusLogs[],
    };
  },
  async created() {
    this.handleLoading(true);
    try {
      this.tasksScheduler = await TaskSchedulerService.findByMasterId();
      this.schedulesStatusLogs = await TaskSchedulerService.statusLogsByMasterId();
    } catch {
      this.errorToast({
        text: this.$t('taskManager.toast.getTasksFail')as string,
      });
    }
    this.loading = false;
    this.handleLoading(false);
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
  },

});
