import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      daysOfWeekOptions: [
        {
          label: this.$t('taskManager.configTask.daysOfWeekFirstLetter.sunday'),
          value: '0',
          name: this.$t('taskManager.configTask.daysOfWeek.sunday'),
        },
        {
          label: this.$t('taskManager.configTask.daysOfWeekFirstLetter.monday'),
          value: '1',
          name: this.$t('taskManager.configTask.daysOfWeek.monday'),
        },
        {
          label: this.$t(
            'taskManager.configTask.daysOfWeekFirstLetter.tuesday',
          ),
          value: '2',
          name: this.$t('taskManager.configTask.daysOfWeek.tuesday'),
        },
        {
          label: this.$t(
            'taskManager.configTask.daysOfWeekFirstLetter.wednesday',
          ),
          value: '3',
          name: this.$t('taskManager.configTask.daysOfWeek.wednesday'),
        },
        {
          label: this.$t(
            'taskManager.configTask.daysOfWeekFirstLetter.thursday',
          ),
          value: '4',
          name: this.$t('taskManager.configTask.daysOfWeek.thursday'),
        },
        {
          label: this.$t('taskManager.configTask.daysOfWeekFirstLetter.friday'),
          value: '5',
          name: this.$t('taskManager.configTask.daysOfWeek.friday'),
        },
        {
          label: this.$t('taskManager.configTask.daysOfWeekFirstLetter.saturday'),
          value: '6',
          name: this.$t('taskManager.configTask.daysOfWeek.saturday'),
        },
      ],
      monthsOptions: [
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.january'),
          value: '1',
          name: this.$t('taskManager.configTask.months.january'),
        },
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.february'),
          value: '2',
          name: this.$t('taskManager.configTask.months.february'),
        },
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.march'),
          value: '3',
          name: this.$t('taskManager.configTask.months.march'),
        },
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.april'),
          value: '4',
          name: this.$t('taskManager.configTask.months.april'),
        },
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.may'),
          value: '5',
          name: this.$t('taskManager.configTask.months.may'),
        },
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.june'),
          value: '6',
          name: this.$t('taskManager.configTask.months.june'),
        },
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.july'),
          value: '7',
          name: this.$t('taskManager.configTask.months.july'),
        },
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.august'),
          value: '8',
          name: this.$t('taskManager.configTask.months.august'),
        },
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.september'),
          value: '9',
          name: this.$t('taskManager.configTask.months.september'),
        },
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.october'),
          value: '10',
          name: this.$t('taskManager.configTask.months.october'),
        },
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.november'),
          value: '11',
          name: this.$t('taskManager.configTask.months.november'),
        },
        {
          label: this.$t('taskManager.configTask.abbreviationMonths.december'),
          value: '12',
          name: this.$t('taskManager.configTask.months.december'),
        },
      ],
    };
  },
  methods: {
    isNumeric(value: string): boolean {
      return !Number.isNaN(+value);
    },
    minuteAndHourToText(minute: string, hour: string): string {
      const cronText = [];

      if (this.isNumeric(hour) && this.isNumeric(minute)) {
        cronText.push(`Às ${hour}:${minute}`);
      } else {
        if (minute === '0') cronText.push();
        else if (minute.includes(',')) cronText.push(`nos minutos ${minute}`);
        else if (minute.includes('-')) cronText.push(`do minuto ${minute.split('-')[0]} ao minuto ${minute.split('-')[1]}`);
        else if (minute.includes('/')) cronText.push(`a cada ${minute.split('/')[1]} minuto`);
        else cronText.push(`no minuto ${minute}`);

        if (hour === '*') cronText.push('de hora em hora');
        else if (hour.includes(',')) cronText.push(`nas horas ${hour}`);
        else if (hour.includes('-')) cronText.push(`das ${hour.split('-')[0]} às ${hour.split('-')[1]} horas`);
        else if (hour.includes('/')) cronText.push(`a cada ${hour.split('/')[1]} horas`);
        else cronText.push(`às ${hour} horas`);
      }

      return cronText.join(', ');
    },
    dayOfMonthToText(dayOfMonth: string): string {
      if (dayOfMonth === '*') return '';
      if (dayOfMonth.includes('/')) return `a cada ${dayOfMonth.split('/')[1]} dias do mês`;
      if (dayOfMonth.includes('-')) return `entre os dias ${dayOfMonth.split('-')[0]} e ${dayOfMonth.split('-')[1]} do mês`;
      if (dayOfMonth.includes(',')) return `no ${this.cronDayOfMonthToText(dayOfMonth)} dia do mês`;
      return `no dia ${dayOfMonth} do mês`;
    },
    monthsToText(months: string): string {
      if (months === '*') return '';
      if (months.includes('/')) return `a cada ${months.split('/')[1]} meses`;
      if (months.includes(',')) return `nos meses de ${this.cronMonthToText(months)}`;
      if (months) return `somente em ${this.cronMonthToText(months)}`;
      return '';
    },
    daysOfWeekToText(daysOfWeek: string): string {
      if (daysOfWeek === '*') return '';
      if (daysOfWeek.includes('/')) return `a cada ${daysOfWeek.split('/')[1]} dias da semana`;
      if (daysOfWeek.includes(',')) return `${daysOfWeek.includes('0') || daysOfWeek.includes('6') ? 'nos' : 'nas'} ${this.cronWeekToText(daysOfWeek)}`;
      if (daysOfWeek) return `somente ${daysOfWeek === '0' || daysOfWeek === '6' ? 'no' : 'na'} ${this.cronWeekToText(daysOfWeek)}`;
      return '';
    },
    cronMonthToText(monthNumber: string): string {
      if (monthNumber.includes(',')) {
        const lastCommaRegex = new RegExp(/,([^,]*)$/);

        return monthNumber.split(',').map(
          (number) => this.monthsOptions.find((month) => month.value === number)?.name as string,
        ).join(', ').replace(lastCommaRegex, ' e$1');
      }
      return this.monthsOptions.find((month) => month.value === monthNumber)?.name as string;
    },
    cronWeekToText(weekNumber: string): string {
      if (weekNumber.includes(',')) {
        const lastCommaRegex = new RegExp(/,([^,]*)$/);

        return weekNumber.split(',').map(
          (number) => `${this.daysOfWeekOptions.find((week) => week.value === number)?.name}s`,
        ).join(', ').replace(lastCommaRegex, ' e$1');
      }
      return this.daysOfWeekOptions.find((week) => week.value === weekNumber)?.name as string;
    },
    cronDayOfMonthToText(dayOfMonth: string): string {
      const lastCommaRegex = new RegExp(/,([^,]*)$/);

      return dayOfMonth.split(',').map((day) => `${day}°`).join(', ').replace(lastCommaRegex, ' e$1');
    },
  },
});
