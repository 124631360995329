import { render, staticRenderFns } from "./TaskManagerHeader.vue?vue&type=template&id=7aa5d6f3&scoped=true&"
import script from "./TaskManagerHeader.vue?vue&type=script&lang=ts&"
export * from "./TaskManagerHeader.vue?vue&type=script&lang=ts&"
import style0 from "./TaskManagerHeader.vue?vue&type=style&index=0&id=7aa5d6f3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aa5d6f3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/roit-company-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7aa5d6f3')) {
      api.createRecord('7aa5d6f3', component.options)
    } else {
      api.reload('7aa5d6f3', component.options)
    }
    module.hot.accept("./TaskManagerHeader.vue?vue&type=template&id=7aa5d6f3&scoped=true&", function () {
      api.rerender('7aa5d6f3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resources/task-manager/components/TaskManagerHeader.vue"
export default component.exports