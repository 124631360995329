import axios from '@/common/http';
import store from '@/store';
import {
  TaskRequest, TaskScheduler, TaskSchedulerCreate, SchedulesStatusLogs,
} from '@/types';

class TaskSchedulerService {
  private service: typeof axios = axios;

  private baseURL = `${process.env.VUE_APP_TASK_SCHEDULER_URL_GATEWAY}`;

  public async create(payload: TaskSchedulerCreate): Promise<TaskScheduler> {
    return (await this.service.post(`${this.baseURL}/schedules`, payload)).data.data[0];
  }

  public async getAllTaskRequests(): Promise<TaskRequest[]> {
    return (await this.service.get(`${this.baseURL}/requests`)).data.data;
  }

  public async update(payload: TaskSchedulerCreate, id: string): Promise<TaskScheduler> {
    return (await this.service.patch(`${this.baseURL}/schedules/${id}`, payload)).data.data;
  }

  public async findByMasterId(): Promise<TaskScheduler[]> {
    return (await this.service.get(`${this.baseURL}/schedules/?masterId=${store.state.auth?.userCompany.masterId}`)).data.data;
  }

  public async delete(id: string) {
    return (await this.service.delete(`${this.baseURL}/schedules/${id}`)).data.data;
  }

  public async pause(id: string): Promise<TaskScheduler> {
    return (await this.service.post(`${this.baseURL}/schedules/pause`, { id })).data.data;
  }

  public async resume(id: string) {
    return (await this.service.post(`${this.baseURL}/schedules/resume`, { id })).data.data;
  }

  public async run(id: string) {
    return (await this.service.post(`${this.baseURL}/schedules/run`, { id })).data.data;
  }

  public async statusLogsByMasterId(): Promise<SchedulesStatusLogs[]> {
    return (await this.service.get(`${this.baseURL}/logs/status?masterId=${store.state.auth?.userCompany.masterId}`)).data.data;
  }
}

export default new TaskSchedulerService();
