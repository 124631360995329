
import { CaptionInterface, TaskScheduler } from '@/types';
import Vue from 'vue';
import Caption from '@/common/components/caption/Caption.vue';

export default Vue.extend({
  name: 'TaskManagerHeader',
  components: {
    TaskManagerConfigTaskModal: () => import('@/resources/task-manager/components/TaskManagerConfigTaskModal.vue'),
    Caption,
  },
  props: {
    showTable: {
      type: Boolean as () => boolean,
      default: true,
    },
    showCalendar: {
      type: Boolean as () => boolean,
      default: false,
    },
    tasksScheduler: {
      type: Array as () => TaskScheduler[],
      required: true,
    },
  },
  data() {
    return {
      showConfigTaskModal: false,
      tableCaptions: [
        { type: 'success', label: 'active' },
        { type: 'fail', label: 'inactive' },
      ] as CaptionInterface[],
      calendarCaptions: [
        { type: 'info', label: 'taskManager.caption.scheduled' },
        { type: 'success', label: 'success' },
        { type: 'fail', label: 'fail' },
      ] as CaptionInterface[],
    };
  },
  methods: {
    handleShowCalendar(): void {
      this.$emit('update:showCalendar', true);
      this.$emit('update:showTable', false);
    },
    handleShowTable(): void {
      this.$emit('update:showCalendar', false);
      this.$emit('update:showTable', true);
    },
  },
});
