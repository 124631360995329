
import TaskManagerLastTaskStatus from '@/resources/task-manager/components/TaskManagerLastTaskStatus.vue';
import { EnumConfigSchedulerStatus, SchedulesStatusLogs, TaskScheduler } from '@/types';
import TaskSchedulerService from '@/common/services/TaskSchedulerService';
import parser from 'cron-parser';
import NotificationMixin from '@/mixins/NotificationMixin';
import cronToTextMixin from '@/mixins/CronToTextMixin';
import { mapMutations } from 'vuex';
import mixins from 'vue-typed-mixins';

export default mixins(cronToTextMixin, NotificationMixin).extend({
  name: 'TaskManagerTable',
  components: {
    TaskManagerLastTaskStatus,
    TaskManagerHistoricModal: () => import('@/resources/task-manager/components/TaskManagerHistoricModal.vue'),
    TaskManagerConfigTaskModal: () => import('@/resources/task-manager/components/TaskManagerConfigTaskModal.vue'),
  },
  mixins: [cronToTextMixin],
  props: {
    tasksScheduler: {
      type: Array as () => TaskScheduler[],
      required: true,
    },
    loading: {
      type: Boolean as () => boolean,
      required: true,
    },
    schedulesStatusLogs: {
      type: Array as () => SchedulesStatusLogs[],
      required: true,
    },
  },
  data() {
    return {
      showHistoricModal: false,
      showConfigTaskModal: false,
      taskSchedulerEditing: {} as TaskScheduler,
      EnumConfigSchedulerStatus,
      initialSort: 'service',
      schedulerLogs: [] as SchedulesStatusLogs[],
      header: {
        alias: [
          this.$t('taskManager.table.alias.service'),
          this.$t('taskManager.table.alias.status'),
          this.$t('taskManager.table.alias.frenquency'),
          this.$t('taskManager.table.alias.lastTask'),
          this.$t('taskManager.table.alias.lastTaskStatus'),
          this.$t('taskManager.table.alias.nextTask'),
        ],
        columnsToShow: [
          'service',
          'status',
          'frequency',
          'lastTask',
          'lastTaskStatus',
          'nextTask',
        ],
      },
    };
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    deleteTask(event: Event, task: TaskScheduler) {
      (this.$confirm as any).require({
        target: event.currentTarget,
        message: this.$t('taskManager.table.comfirmModal.deleteTask.message'),
        header: this.$t('taskManager.table.comfirmModal.deleteTask.header'),
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger task-manager',
        rejectClass: 'task-manager p-button-danger p-button-outlined',
        acceptLabel: this.$t('delete'),
        rejectLabel: this.$t('cancel'),
        accept: async () => {
          try {
            this.handleLoading(true);
            const taskIndex = this.tasksScheduler.findIndex(
              (taskScheduler) => taskScheduler.id === task.id,
            );
            await TaskSchedulerService.delete(task.id);
            this.tasksScheduler.splice(taskIndex, 1);
            this.successToast({
              text: this.$t('taskManager.toast.delete')as string,
            });
          } catch {
            this.errorToast({
              text: this.$t('taskManager.toast.deleteFail')as string,
            });
          }
          this.handleLoading(false);
        },
      });
    },
    runOrResumeTask(event: Event, task: TaskScheduler) {
      (this.$confirm as any).require({
        target: event.currentTarget,
        message: this.getRunOrResumeTaskLocale(task.status, 'Message'),
        header: this.getRunOrResumeTaskLocale(task.status, 'Header'),
        icon: 'pi pi-info-circle',
        acceptClass: 'task-manager',
        rejectClass: 'task-manager outlined',
        acceptLabel: task.status === EnumConfigSchedulerStatus.Paused ? this.$t('resume') : this.$t('run'),
        rejectLabel: this.$t('cancel'),
        accept: async () => {
          this.handleLoading(true);
          if (task.status === EnumConfigSchedulerStatus.Paused) {
            try {
              await TaskSchedulerService.resume(task.id);
              const taskResumed = this.tasksScheduler.findIndex(
                (taskScheduler) => taskScheduler.id === task.id,
              );
              this.tasksScheduler[taskResumed].status = EnumConfigSchedulerStatus.Enabled;
              this.successToast({
                text: this.$t('taskManager.toast.resume')as string,
              });
            } catch {
              this.errorToast({
                text: this.$t('taskManager.toast.resumeFail')as string,
              });
            }
          } else {
            try {
              await TaskSchedulerService.run(task.id);
              this.successToast({
                text: this.$t('taskManager.toast.run')as string,
              });
            } catch {
              this.errorToast({
                text: this.$t('taskManager.toast.runFail')as string,
              });
            }
          }
          this.handleLoading(false);
        },
      });
    },
    pauseTask(event: Event, id: string) {
      (this.$confirm as any).require({
        target: event.currentTarget,
        message: this.$t('taskManager.table.comfirmModal.pauseTask.message'),
        header: this.$t('taskManager.table.comfirmModal.pauseTask.header'),
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger task-manager',
        rejectClass: 'p-button-danger p-button-outlined task-manager',
        acceptLabel: this.$t('pause'),
        rejectLabel: this.$t('cancel'),
        accept: async () => {
          this.handleLoading(true);
          try {
            await TaskSchedulerService.pause(id);
            const taskPaused = this.tasksScheduler.findIndex(
              (task) => task.id === id,
            );
            this.tasksScheduler[taskPaused].status = EnumConfigSchedulerStatus.Paused;
            this.successToast({
              text: this.$t('taskManager.toast.pause') as string,
            });
          } catch {
            this.errorToast({
              text: this.$t('taskManager.toast.pauseFail') as string,
            });
          }
          this.handleLoading(false);
        },
      });
    },
    getRunOrResumeTaskLocale(status: string, type: string): string {
      const message = (
        status === EnumConfigSchedulerStatus.Paused
          ? this.$t(`taskManager.table.comfirmModal.runTask.resume${type}`)
          : this.$t(`taskManager.table.comfirmModal.runTask.run${type}`)
      );
      return message as string;
    },
    handleTaskConfigModal(task: TaskScheduler): void {
      this.taskSchedulerEditing = task;
      this.showConfigTaskModal = true;
    },
    getNextRun(cron: string): string {
      const date = parser.parseExpression(cron).next().toISOString();

      return this.$moment(date).format('lll');
    },
    getPrevRun(task: TaskScheduler): string {
      const executedAt = this.schedulesStatusLogs.find(
        (log) => log.schedulerId === task.id,
      )?.executedAt;

      if (executedAt) {
        const date = this.$moment(executedAt).parseZone().toISOString();

        return this.$moment(date).format('lll');
      }
      return this.$t('taskManager.table.status.notRun') as string;
    },
    cronText(cron: string): string {
      const cronText = [];
      const [minute, hour, dayOfMonth, months, daysOfWeek] = cron.split(' ');

      cronText.push(this.minuteAndHourToText(minute, hour));
      cronText.push(this.dayOfMonthToText(dayOfMonth));
      cronText.push(this.monthsToText(months));
      cronText.push(this.daysOfWeekToText(daysOfWeek));
      const text = cronText.filter((value) => value !== '').join(', ');

      return this.capitalize(text);
    },
    capitalize(text: string): string {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },

    handleHistoricModal(id: string): void {
      this.schedulerLogs = this.filterLogs(id);
      this.showHistoricModal = true;
    },
    getLastStatusTask(task: TaskScheduler): string | undefined {
      return this.schedulesStatusLogs.find((log) => log.schedulerId === task.id)?.statusCode;
    },
    filterLogs(id: string): SchedulesStatusLogs[] {
      return this.schedulesStatusLogs.filter((log) => log.schedulerId === id);
    },
  },

});
