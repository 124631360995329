
import Vue from 'vue';

export default Vue.extend({
  name: 'TaskManagerLastTaskStatus',
  props: {
    status: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    getStatus(): string {
      if (this.status === '200' || this.status === '201') {
        return 'success';
      }

      return 'fail';
    },
  },
});
