var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ConfirmDialog"),
      _c("TaskManagerHistoricModal", {
        attrs: {
          "show-modal": _vm.showHistoricModal,
          "scheduler-logs": _vm.schedulerLogs,
        },
        on: {
          "update:showModal": function ($event) {
            _vm.showHistoricModal = $event
          },
          "update:show-modal": function ($event) {
            _vm.showHistoricModal = $event
          },
        },
      }),
      _c("TaskManagerConfigTaskModal", {
        attrs: {
          "show-modal": _vm.showConfigTaskModal,
          "task-scheduler": _vm.taskSchedulerEditing,
          "tasks-scheduler": _vm.tasksScheduler,
        },
        on: {
          "update:showModal": function ($event) {
            _vm.showConfigTaskModal = $event
          },
          "update:show-modal": function ($event) {
            _vm.showConfigTaskModal = $event
          },
        },
      }),
      _c("custom-table", {
        attrs: {
          "columns-data": _vm.header,
          data: _vm.tasksScheduler,
          loading: _vm.loading,
          "row-length": _vm.tasksScheduler.length,
          paginate: _vm.tasksScheduler && _vm.tasksScheduler.length > 25,
          "initial-sort": _vm.initialSort,
        },
        scopedSlots: _vm._u([
          {
            key: "columns",
            fn: function ({ item, column }) {
              return [
                column === "service"
                  ? _c("td", { staticClass: "service" }, [
                      _c("div", { staticClass: "flex items-center h-full" }, [
                        (
                          item.status
                            ? item.status.toUpperCase() === "ENABLED"
                            : false
                        )
                          ? _c("div", {
                              staticClass:
                                "service__status service__status--success",
                            })
                          : _c("div", {
                              staticClass:
                                "service__status service__status--error",
                            }),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  `taskManager.table.services.${item.service}`
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                column === "status"
                  ? _c("td", [
                      _c("div", { staticClass: "flex items-center h-full" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                `taskManager.table.status.${item.status.toLowerCase()}`
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                column === "frequency"
                  ? _c("td", [
                      _c("div", { staticClass: "flex items-center h-full" }, [
                        _vm._v(
                          " " + _vm._s(_vm.cronText(item.config.cron)) + " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                column === "lastTask"
                  ? _c("td", [
                      _c("div", { staticClass: "flex items-center h-full" }, [
                        _vm._v(" " + _vm._s(_vm.getPrevRun(item)) + " "),
                      ]),
                    ])
                  : _vm._e(),
                column === "lastTaskStatus"
                  ? _c("td", [
                      _c(
                        "div",
                        { staticClass: "flex items-center h-full" },
                        [
                          _c("TaskManagerLastTaskStatus", {
                            attrs: { status: _vm.getLastStatusTask(item) },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                column === "nextTask"
                  ? _c("td", [
                      _c("div", { staticClass: "flex items-center h-full" }, [
                        _vm._v(
                          " " + _vm._s(_vm.getNextRun(item.config.cron)) + " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item }) {
              return [
                _c("div", { staticClass: "actions" }, [
                  item.status === _vm.EnumConfigSchedulerStatus.Enabled
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top",
                              value: _vm.$t("edit"),
                              expression: "$t('edit')",
                              modifiers: { top: true },
                            },
                          ],
                          staticClass: "action",
                          on: {
                            click: function ($event) {
                              return _vm.handleTaskConfigModal(item)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon-r-edit bg-color-secondary",
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.filterLogs(item.id).length > 0
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top",
                              value: _vm.$t("historic"),
                              expression: "$t('historic')",
                              modifiers: { top: true },
                            },
                          ],
                          staticClass: "action",
                          on: {
                            click: function ($event) {
                              return _vm.handleHistoricModal(item.id)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "icon-r-file-see bg-color-secondary",
                          }),
                        ]
                      )
                    : _vm._e(),
                  item.status === _vm.EnumConfigSchedulerStatus.Enabled
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top",
                              value: _vm.$t("pause"),
                              expression: "$t('pause')",
                              modifiers: { top: true },
                            },
                          ],
                          staticClass: "action",
                          on: {
                            click: function ($event) {
                              return _vm.pauseTask($event, item.id)
                            },
                          },
                        },
                        [_c("i", { staticClass: "icon-r-pause bg-red-600" })]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value:
                            item.status === "Paused"
                              ? _vm.$t("resume")
                              : _vm.$t("run"),
                          expression:
                            "item.status === 'Paused' ? $t('resume') : $t('run')",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "action",
                      on: {
                        click: function ($event) {
                          return _vm.runOrResumeTask($event, item)
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-r-play bg-green-700" })]
                  ),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.$t("exclude"),
                          expression: "$t('exclude')",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "action",
                      on: {
                        click: function ($event) {
                          return _vm.deleteTask($event, item)
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon-r-trash" })]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }